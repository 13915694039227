<template>
  <div class="scroll-wrapper">
    <div class="task-wrapper">
      <FeedbackDisplay v-if="shouldBeShowingFeedback"
                       :on-press-next="closeFeedback"
                       :was-correct="showCorrectVideo"/>
      <div v-else>
        <SlideHeaderQuiz v-if="isQuiz"
                         :description="slideContent.questionContent.question"
                         :title="slideContent.title"/>
        <slide-header v-else :description="slideContent.description" :title="slideContent.title"/>

        <div id="answers"
             :style="historyLocation !== null ? 'pointer-events: none' : ''"
             class="multiple-choice-answers">
          <div v-for="(answer, index) in slideContent.questionContent.answers"
               :key="answer.id"
               :class="{'answers-submitted' : correctAnswers.length > 0}"
               class="answer-wrapper"
          >
            <div class="answer-letter">{{ alphabet[index] }}.</div>
            <div :class="[{'answer-removed': removedAnswers.includes(answer.id)},
                          {'selected-answer': [...givenAnswers,...selectedAnswers].includes(answer.id)},
                          {'incorrect-answer': correctAnswers.length > 0 && selectedAnswers.includes(answer.id) && !correctAnswers.includes(answer.id)},
                          {'correct-answer': correctAnswers.includes(answer.id)}]"
                 class="answer">
              <label :for="`answer-${answer.id}`" class="check-label">
                <input :id="`answer-${answer.id}`"
                       v-model="selectedAnswers"
                       :disabled="result"
                       :value="answer.id"
                       name="basic-checkbox"
                       type="checkbox"
                />
                <span class="check-mark"></span>
                <span class="answer-text">{{ answer.answer }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>

    </div>

  </div>
  <game-footer v-if="!shouldBeShowingFeedback"
               :continue-button-click="checkAnswers"
               :continue-button-disabled="selectedAnswers.length <= 0"

               :continue-button-text="$t('FOOTER.SINGLE_ANSWER.CONTINUE_BUTTON_TEXT')"
               :feedback-button-click="showFeedback"

               :feedback-button-text="$t('FOOTER.FEEDBACK_BUTTON_TEXT')"
               :feedback-result="result"
               :force-next="shouldBeShowingFeedback"
               :has-feedback-popup="feedbackText"/>
</template>

<script>
import { POST_SLIDE_ANSWER, POST_SLIDE_ANSWER_MEDIA_QUIZ } from '@/store/actions'
import { UPDATE_BITS } from '@/store/mutations'
import SlideHeader from '@/components/elements/SlideHeader'
import GameFooter from '@/components/GameFooter'
import i18n from '@/setup/i18n/i18n'
import SlideHeaderQuiz from '@/components/elements/SlideHeaderQuiz'
import { mapGetters } from 'vuex'
import FeedbackDisplay from './FeedbackDisplay'

export default {
  name: 'MultipleChoice',
  components: { FeedbackDisplay, SlideHeaderQuiz, GameFooter, SlideHeader },
  props: {
    slideContent: {
      type: Object,
      required: true,
    },
    isQuiz: {
      type: Boolean,
      required: false,
      default: false
    },
    classId: {
      type: Number,
      required: false,
      default: null
    }
  },
  emits: ['nextSlide'],
  data: () => ({
    alphabet: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'],
    selectedAnswers: [],
    result: undefined,
    //correctAnswers: [],
    feedbackText: '',
    feedbackVideoUrl: '',
    removedAnswers: []
  }),
  computed: {
    ...mapGetters({
      jokerResult: 'getJokerResult',
      //correctAnswers: 'getCorrectAnswers',
      //givenAnswers: 'getClientAnswer'
      historyLocation: 'getHistoryLocation',
      showFeedbackModal: 'getShowFeedbackModal'

    }),
    shouldBeShowingFeedback() {
      return this.historyLocation?.isPostAnswer || this.showFeedbackModal
    },
    correctAnswers() {
      const answers = this.$store.getters.getCorrectAnswers
      if (answers === undefined || answers === null) {
        return []
      }
      console.log('got correct answers', answers)

      if (Array.isArray(answers)) {
        return answers
      } else if (typeof answers === 'object') {
        return [...Object.values(answers)]
      } else {
        return [answers]
      }
    },
    givenAnswers() {
      /*      if (this.selectedAnswers.length > 0) {
              return this.selectedAnswers
            }*/

      const answers = this.$store.getters.getClientAnswer
      console.log('got given answers', answers)
      if (answers == null) {
        return []
      }

      if (Array.isArray(answers)) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = answers
        return answers
      } else {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedAnswers = [answers]
        return [answers]
      }
    },
    showCorrectVideo() {
      return this.correctAnswers.every((answer) => this.givenAnswers.includes(answer)) && this.correctAnswers?.length === this.givenAnswers?.length
    },
  },
  watch: {
    jokerResult: {
      deep: true,
      handler: function(curr, prev) {
        if (curr && curr.wrongAnswersToRemove) {
          this.removedAnswers = curr.wrongAnswersToRemove.map(value => value.id)
          if (this.removedAnswers.includes(this.selectedAnswer)) {
            this.selectedAnswer = null
          }
        }
      }
    },
    /*    givenAnswers: {
          deep: true,
          handler: function(curr, prev) {
            console.log('given answers changed', curr)
            if (curr && curr.length > 0) {
              this.selectedAnswers = curr
            }
          }
        }*/
  },
  // mounted() {
  // },
  methods: {
    checkAnswers() {
      let storeDispatchFunction

      const formData = new FormData()
      if (this.isQuiz) {
        storeDispatchFunction = POST_SLIDE_ANSWER_MEDIA_QUIZ
        const schoolData = localStorage.getItem('schoolClass')
        if (schoolData) {
          const schoolDataParsed = JSON.parse(schoolData)
          formData.append('schoolClassId', schoolDataParsed.schoolClass.id)
        }
        formData.append('mediaQuizSlideId', this.slideContent.mediaQuizSlideId)
      }
      // mission
      else {
        storeDispatchFunction = POST_SLIDE_ANSWER
        formData.append('missionId', this.slideContent.missionId)
        formData.append('slideIndex', this.slideContent.slideIndex)
      }

      this.selectedAnswers.map((answer, index) => {
        formData.append(`answer[${index}]`, answer)
      })
      this.$store.dispatch(storeDispatchFunction, formData)
        .then(response => {
          if (!(response.correctAnswer instanceof Array)) {
            response.correctAnswer = Object.values(response.correctAnswer)
          }
          //this.correctAnswers = response.correctAnswer

          const successPercentage = this.getSuccessPercentage()
          if (successPercentage < 1) {
            /**
             * Wrong answer, will activate on 1 wrong answer
             */
            // Setup feedback answer
            let correctAnswer = ''
            response.correctAnswer.map((answerId, index) => {
              correctAnswer += this.slideContent.questionContent.answers.filter(elem => elem.id === answerId)[0].answer + (index + 1 === response.correctAnswer.length ? '' : ', ')
            })
            this.result = {
              successPercentage: successPercentage,
              title: i18n.global.tc('FOOTER.MULTIPLE_ANSWERS.FEEDBACK_TITLE'),
              text: correctAnswer
            }
          } else {
            /**
             * Correct answer
             */
            this.result = {
              successPercentage: successPercentage,
              title: i18n.global.tc('FOOTER.GOOD_JOB'),
            }
          }
          // console.log('MultipleChoice response', response)
          this.feedbackText = response.feedback
          this.feedbackVideoUrl = response.feedbackVideoUrl
          this.$store.commit(UPDATE_BITS, response.awardedBits)

          this.$nextTick().then(() => {
            const mainContent = document.getElementById('main-content')
            if (mainContent) {
              mainContent.scrollTop = mainContent.scrollHeight
            }
          })
        })
        .catch(error => console.log(error))
    },
    showFeedback() {
      // Show feedback modal
      if (this.feedbackText || this.feedbackVideoUrl) {
        this.$store.dispatch('showFeedbackModal')
      } else {
        this.$emit('nextSlide', this.result && this.result.successPercentage === 1)
      }
    },
    closeFeedback() {
      // Hides feedback modal
      this.$store.dispatch('hideFeedbackModal')
      this.$emit('nextSlide', this.result && this.result.successPercentage === 1)
    },
    getSuccessPercentage() {
      if (!this.correctAnswers.length || !this.selectedAnswers.length) return -1

      const foundCorrect = this.selectedAnswers.filter(answer => this.correctAnswers.includes(answer))
      const percentageCorrect = foundCorrect.length / Math.max(this.correctAnswers.length, this.selectedAnswers.length)
      // console.log('correct', percentageCorrect)
      return percentageCorrect
      let correctAmount = 0
      for (let i = 0; i < this.selectedAnswers.length; i++) {
        if (this.correctAnswers[i] === this.selectedAnswers[i]) {
          correctAmount++
        }
      }
      return correctAmount / this.selectedAnswers.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/mission.scss";
@import "~@/assets/css/quiz.scss";

.answer-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: rem(15);
  }

  .answer-letter {
    font-size: rem(24);
    font-weight: 800;
  }

  .answer {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    width: 80%;
    font-size: rem(22);
    font-weight: 700;
    margin-left: rem(15);
    padding: 0 rem(10);
    border-radius: rem(10);
    color: var(--text_color);
    transition: background-color 0.15s, color 0.15s;

    @media screen and (min-width: $screen-xl) {
      font-size: rem(18);
    }
  }

  .selected-answer {
    background-color: var(--primary_color);
    color: #FFFFFF;
  }

  .answer-text {
    user-select: none;
  }

  .answer-removed {
    pointer-events: none;
    background-color: #9590A4;
  }

  .incorrect-answer {
    background-color: #d93033;
  }

  .correct-answer {
    background-color: #00998f;
    color: #FFF;
  }
}

.answers-submitted {
  .answer {
    opacity: 0.5;
  }

  .selected-answer {
    opacity: 1;
  }
}

.check-label {
  margin: 0;
  padding: rem(12) rem(10) rem(12) rem(30);
  display: block;
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;

  input {
    position: absolute;
    opacity: 0;

    &:checked ~ .check-mark {
      background-color: #FFFFFF;
      border: none;
      transition: background-color 0.15s;

      &:after {
        opacity: 1;
      }
    }
  }

  .check-mark {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    border-radius: rem(4);
    height: rem(27);
    width: rem(27);
    border: 2px solid var(--primary_color);
    background-color: white;
    transition: background-color 0.15s;

    &:after {
      transition: opacity 0.15s;
      content: "";
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - #{rem(2.3)})) rotate(45deg);

      width: rem(10);
      height: rem(18);
      border: solid var(--primary_color);
      border-width: 0 rem(4) rem(4) 0;
    }
  }

  .check-mark-error {
    @extend .check-mark;
    border: 2px solid red;
  }
}

// pure mobile portrait
@media screen and (max-width: $screen-mobile) {
  .multiple-choice-answers {
    margin-top: 16px;
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  .quiz-wrapper .task-wrapper {
    padding-top: 0;
  }
  //.scroll-wrapper {
  //  background: red;
  //}
}

@media screen and (max-width: $screen-720p) {
  .check-label {
    padding: rem(10) rem(8) rem(10) rem(30);
  }

  .answer-wrapper {
    .answer-letter {
      font-size: rem(18);
    }

    .answer {
      font-size: rem(15);
    }
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  .quiz-wrapper .task-wrapper {
    padding-left: 16px;
    padding-right: 16px;
  }
}

</style>
