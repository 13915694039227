<template>
  <div class="content-wrapper">
    <!-- Upper content -->
    <div ref="upperSectionHeight" class="top-content">
      <div
        v-if="onWeek"
        :class="{'blue-background-extra':showSubsection==='extra-games', 'blue-background-media':showSubsection==='media-quiz'}"
        class="blue-background"
      >
      </div>

      <!-- Header -->
      <ExtraGamesHeader :days-till-end="timeUntil.days"
                        :hours-till-end="timeUntil.hours"
                        :logged-in-data="loggedInData"
                        :on-week="onWeek"
                        @logout="logout"
      />

      <!-- Top content options -->
      <div v-if="onWeek" class="top-content-options">
        <div class="top-content-media-quiz">
          <h1 class="title">MEDIA<br/> QUIZ</h1>

          <p class="description">
            Speel de MediaQuiz en verdien vandaag
            {{ loggedInData ? Math.round(loggedInData.mediaquiz.maxBitsEarnable) : '' }} bits voor je
            klas. De volgende dag is
            opnieuw dit maximale aantal bits te behalen.</p>
          <BitsBar
            v-if="loggedInData && onWeek && showSubsection === null"
            :bit-count="bitsEarnedTodayMediaQuiz"
            :maximum-bits="Math.round(loggedInData?.mediaquiz.maxBitsEarnable)"
            :percentage="loggedInData.mediaquiz.progress"
            class="bits-bar bits-bar-left"
          />
          <BasicButton v-if="showSubsection !== 'media-quiz'"
                       :button-text="$t('MEDIA_QUIZ.LOOK_AT_GAMES')"
                       @click="showSubsection = 'media-quiz'; goToLowerSection()"/>
        </div>

        <div class="top-content-extra-games">
          <h1 class="title">EXTRA<br/> GAMES</h1>
          <p class="description">Ga aan de slag met de extra games en verdien vandaag
            {{ loggedInData ? Math.round(loggedInData?.extraGames.maxBitsEarnable) : '' }} bits voor je klas. De
            volgende dag is opnieuw dit maximale aantal bits te behalen.</p>
          <BitsBar
            v-if="loggedInData && showSubsection === null"
            :bit-count="bitsEarnedToday"
            :maximum-bits="Math.round(loggedInData?.extraGames.maxBitsEarnable)"
            :percentage="bitsEarnedToday/ Math.round(loggedInData.extraGames.maxBitsEarnable)"
            class="bits-bar bits-bar-right"
          />
          <BasicButton v-if="showSubsection !== 'extra-games'"
                       :button-text="$t('EXTRA_GAMES.LOOK_AT_GAMES')"
                       @click="showSubsection='extra-games'; goToLowerSection()"/>
        </div>

        <img :src="require('@/assets/images/img_mediaicon.png')" class="shield-center" @click="startedMediaQuiz"/>
      </div>
    </div>

    <!-- Lower section if media quiz is selected -->
    <Transition mode="out-in" name="slide-left" @before-enter="beforeEnterMedia" @after-leave="afterLeaveMedia">
      <div v-if="showSubsection === 'media-quiz' && extraLeft" class="lower-section-media-quiz">
        <img :src="require('@/assets/images/img_mediabanner.png')" class="media-quiz-banner-img"/>
        <div class="media-quiz-title-wrapper">
          <h2 class="media-quiz-title">MEDIA</h2>
          <h2 class="media-quiz-title-big">QUIZ</h2>
        </div>
        <MediaQuizBanner v-if="onWeek"
                         :earned="loggedInData?.mediaquiz.bitsEarned ?? 0"
                         :is-logged-in="loggedInData !== null"
                         :maximum-bits="loggedInData?.mediaquiz.maxBitsEarnable ?? 0"
                         :progress="loggedInData?.mediaquiz.progress ?? 0"
                         :started-media-quiz="showMediaQuizPopup"
                         class="media-quiz-banner"
        />
      </div>
    </Transition>

    <!-- Lower section if extra's is selected -->
    <Transition mode="out-in" name="slide-right" @before-enter="beforeEnterExtra" @after-leave="afterLeaveExtra">
      <div v-if="showSubsection === 'extra-games' && mediaLeft" class="lower-section-extra">
        <div v-if="onWeek" class="progress-bar-wrapper">
          <DailyBitsEarned
            v-if="loggedInData"
            :bits-per-day="loggedInData.extraGames.bitsPerDay"
            :current-day="weekStatus.currentDay"
            :end-date="weekStatus.endDate"
            :logged-in-data="loggedInData"
            :max-bits="loggedInData.extraGames.maxBitsEarnable"
            :start-date="weekStatus.startDate"
          />
        </div>
        <div v-if="onWeek">
          <p class="daily-bits-text">Jullie kunnen per dag een maximaal aantal bits behalen. De dag daarna kunnen jullie
            dus weer opnieuw het maximale aantal bits verdienen.</p>
          <p v-if="!extraGames || !extraGames.length" style="text-align: center; color: white;">
            Er zijn nog geen extra games beschikbaar
          </p>
        </div>
        <div v-if="loggedInData && bitsEarnedToday > loggedInData.extraGames.maxBitsEarnable - 1"
             class="maximum-bits-reached-message">
          <p class="maximum-bits-reached-title">Goed bezig! Je scoorde vandaag het maximale aantal bits op MediaMasters
            Extra met je klas.</p>
          <p class="maximum-bits-reached-description">Je kunt blijven spelen, maar deze bits tellen niet mee. Morgen
            begint de teller opnieuw.</p>
        </div>

        <div v-if="!onWeek">
          <h2 class="lower-section-title"> {{ $t('EXTRA_GAMES.THEME_MISSIONS') }}</h2>
          <div class="game-tile-wrapper">
            <p v-if="!themeMissions || !themeMissions.length" style="text-align: center; color: white;">
              {{ $t('EXTRA_GAMES.NO_THEME_MISSIONS_AVAILABLE') }}
            </p>
            <ThemeMissionBox
              v-for="mission in themeMissions"
              :key="mission.id"
              :description="mission.description"
              :image-src="mission.mediaFileUrl"
              :on-button-click="() => startThemeMission(mission.id)"
              :title="mission.name"
            />
          </div>
        </div>

        <!--        <div class="pre-week-section">
                  <div class="pre-week-section-inner">
                    <GameBox
                      v-if="!onWeek || !loggedInData"
                      :game-data="mediaQuizBoxData"
                      :is-logged-in="loggedInData !== null"
                      :on-week="onWeek"
                      @click="showMediaQuizPopup"
                    />
                  </div>

                </div>-->

        <h2 class="lower-section-title"> {{ $t('EXTRA_GAMES.GAMES') }}</h2>
        <div class="game-tile-wrapper">
          <p v-if="!extraGames || !extraGames.length" style="text-align: center; color: white;">
            Er zijn nog geen extra games beschikbaar
          </p>
          <InfoGameBox
            v-if="onWeek && loggedInData"
            :extra-games="extraGames"
          />
          <GameBox
            v-if="!onWeek || !loggedInData"
            :game-data="mediaQuizBoxData"
            :is-logged-in="loggedInData !== null"
            :logged-in-data="loggedInData"
            :on-week="onWeek"
            @click="showMediaQuizPopup"
          />
          <GameBox
            v-for="data in extraGames"
            :key="data.id"
            :game-data="data"
            :is-logged-in="loggedInData !== null"
            :logged-in-data="loggedInData"
            :on-week="onWeek"
            class="extra-game"
            @click="isMobile ? showNoExtraGamesModal(data) : showDetailsModal(data)"
          />
        </div>
      </div>
    </Transition>

    <!-- Modals -->
    <GameDetailsModal
      :bits-earned="currentDetailModalData.bitsEarned"
      :close-button-pressed="currentDetailModalData.closeButtonPressed"
      :description="currentDetailModalData.description"
      :image-src="currentDetailModalData.imageSrc"
      :is-logged-in="loggedInData !== null"
      :is-shown="currentDetailModalData.active"
      :maximum-bits-reached="currentDetailModalData.maximumBitsReached"
      :on-week="onWeek"
      :reward-amount="currentDetailModalData.rewardAmount"
      :route="currentDetailModalData.route"
      :start-button-pressed="currentDetailModalData.startButtonPressed"
      :times-played="currentDetailModalData.timesPlayed"
      :title="currentDetailModalData.title"
      :use-inline-svg="currentDetailModalData.useInlineSvg"
    />
    <GameCompletedModal
      v-if="gameCompletedModalData"
      :bits-awarded="gameCompletedModalData.bitsAwarded"
      :class-name="loggedInData.schoolClass.name"
      :extra-game-object="gameCompletedModalData.extraGameObject"
      :is-week-active="onWeek"
      :on-ok-button-pressed="closeCompletedGameModal"
    />
    <NoExtraGamesModal
      :is-shown="isNoExtraGamesModalShown"
      @on-close="closeNoExtraGamesShownModal"
    />
  </div>
</template>

<script>
import { watch } from 'vue'
import { computed, ref } from '@vue/reactivity'
import ApiHandler from '@/utils/handlers/ApiHandler'
import { GET_WEEK_STATUS } from '@/store/actions.js'
import InfoGameBox from '@/components/partials/extras/InfoGameBox'
import GameBox from '@/components/partials/extras/GameBox'
import MediaQuizBanner from '../components/elements/extras/MediaQuizBanner'
import ExtraGamesHeader from '../components/header/ExtraGamesHeader.vue'
import { useStore } from 'vuex'
import GameDetailsModal from '../components/partials/modal/GameDetailsModal'
import ThemeMissionBox from '@/components/partials/extras/ThemeMissionBox'
import { useRoute, useRouter } from 'vue-router'
import { ROUTE_NAMES } from '@/router'
import GameCompletedModal from '@/components/partials/modal/GameCompletedModal'
import DomHandler from '@/utils/handlers/DomHandler'
import NoExtraGamesModal from '@/components/partials/modal/NoExtraGamesModal'
import { parseDateFromSqlSafariSupported } from '@/utils/dateUtils'
import DailyBitsEarned from '../components/elements/extras/DailyBitsEarned.vue'
import BasicButton from '@/components/elements/basic/BasicButton.vue'
import BitsBar from '@/components/elements/progress/BitsBar.vue'

const BASE_EXTRA_GAME_MODAL_DATA = {
  active: false,
  title: '',
  description: '',
  timesPlayed: 0,
  rewardAmount: 0,
  imageSrc: '',
  maximumBitsReached: false,
  useInlineSvg: false,
}

export default {
  name: 'Extras',
  components: {
    NoExtraGamesModal,
    InfoGameBox,
    GameBox,
    MediaQuizBanner,
    ExtraGamesHeader,
    GameDetailsModal,
    ThemeMissionBox,
    GameCompletedModal,
    DailyBitsEarned,
    BasicButton,
    BitsBar,
  },
  setup() {
    const extraGames = ref(null)
    const themeMissions = ref(null)
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const gameCompletedModalData = ref(null)

    // Animations
    // See if the media or extra section left the screen to prevent both animation triggering on the same time
    const mediaLeft = ref(true)
    const extraLeft = ref(true)

    function beforeEnterMedia() {
      mediaLeft.value = false
    }

    function beforeEnterExtra() {
      extraLeft.value = false
    }

    function afterLeaveMedia() {
      mediaLeft.value = true
      extraLeft.value = false
    }

    function afterLeaveExtra() {
      extraLeft.value = true
      mediaLeft.value = false
    }

    const queryClassId = computed(() => Number.parseInt(route.query['class-id']))
    const queryNoClass = computed(() => route.query['no-class'])
    const queryGameId = computed(() => Number.parseInt(route.query['game-id']))
    const queryExtraGameCompletedId = computed(() => Number.parseInt(route.query['extra-game-completed-id']))
    const queryBitsAwarded = computed(() => Number.parseInt(route.query['bits-awarded']))

    const loggedInData = ref(null)
    const currentDetailModalData = ref(BASE_EXTRA_GAME_MODAL_DATA)

    const weekStatus = computed(() => store.getters.getWeekStatus)
    store.dispatch(GET_WEEK_STATUS)
    const timeUntil = computed(() => {
      if (weekStatus?.value?.endDate) {
        const endDateDate = parseDateFromSqlSafariSupported(weekStatus.value.endDate)
        return getTimeUntil(endDateDate, new Date())
      }
      return ''
    })

    // const preWeek = ref(false)
    const preWeek = computed(() => weekStatus.value.status === 'upcoming')

    // const onWeek = ref(true)
    const onWeek = computed(() => weekStatus.value.status === 'active')

    // const postWeek = ref(false)
    const postWeek = computed(() => weekStatus.value.status === 'passed' || weekStatus.value.status === 'passed_pre_ranking')

    // Has three values, null, 'media-quiz' and 'extra-games'
    const showSubsection = ref(null)
    watch(() => weekStatus.value, () => {
      if (preWeek.value || postWeek.value) {
        showSubsection.value = 'extra-games'
      }
    })

    const isNoExtraGamesModalShown = ref(false)
    const isMobile = computed(() => DomHandler.isMobile())

    const bitsEarnedToday = computed(() => !onWeek.value ? 0 : loggedInData.value.extraGames.bitsPerDay[weekStatus.value.currentDay - 1])

    const bitsEarnedTodayMediaQuiz = computed(() => !onWeek.value ? 0 : loggedInData.value.mediaquiz.bitsPerDay[weekStatus.value.currentDay - 1])
    let currentLoginAPICall = null

    // console.log('preWeek', preWeek.value)
    function getTimeUntil(endDate, currentDate) {
      const timeUntil = {
        days: -1,
        hours: -1,
      }

      if (!endDate || endDate < currentDate) {
        return timeUntil
      }
      let diffInSeconds = (endDate - currentDate) / 1000

      timeUntil.days = Math.floor(diffInSeconds / 86400)
      diffInSeconds -= timeUntil.days * 86400

      timeUntil.hours = Math.floor(diffInSeconds / 3600) % 24
      return timeUntil
    }

    let localStorageLoggedInData = localStorage.getItem('schoolClass')

    if (queryClassId.value) {
      const formData = new FormData()

      formData.append('classId', queryClassId.value)
      currentLoginAPICall = loginClass(formData)
    } else if (localStorageLoggedInData) {
      localStorageLoggedInData = JSON.parse(localStorageLoggedInData)
      // console.log('localStorageLoggedInData', localStorageLoggedInData)
      const formData = new FormData()

      formData.append('className', localStorageLoggedInData.schoolClass.name)
      currentLoginAPICall = loginClass(formData)
    } else {
      if (!preWeek.value) {
        getExtraGames()
      }
    }

    function loginClass(formData) {
      // console.log('postWEek', postWeek.value)
      if (queryNoClass.value || postWeek.value) {
        logout()
        getExtraGames()
        return
      }
      return ApiHandler.api.post('/auth/login-extra-game-class', formData)
        .then((res) => {
          localStorage.setItem('schoolClass', JSON.stringify(res.data))

          if (queryClassId.value) {
            router.replace({
              ...router.currentRoute,
              query: {
                'class-id': undefined
              }
            })
          }

          loggedInData.value = { ...res.data }
          if (!preWeek.value) {
            getExtraGames()
          }
        })
        .catch((error) => {
          console.log('error', error)
          if (!preWeek.value) {
            getExtraGames()
          }
        })
    }

    const mediaQuizBoxData = {
      id: -1,
      name: 'MediaQuiz',
      mediaFileUrl: require('../assets/images/img_games_mediaquiz.png')
    }

    function getExtraGames() {
      const classIdQuery = loggedInData.value ? `schoolClassId=${loggedInData.value.schoolClass.id}` : ''
      return ApiHandler.api.get(`game/extra-games/get-extra-games?${classIdQuery}`)
        .then(response => {
          if (response) {
            extraGames.value = response.data
            // console.log('extraGames.value', extraGames.value)

            if (queryExtraGameCompletedId.value && (queryBitsAwarded.value || queryBitsAwarded.value === 0)) {
              router.replace({
                ...router.currentRoute,
                query: {
                  'extra-game-completed-id': undefined,
                  'bits-awarded': undefined
                }
              })

              const bits = queryBitsAwarded.value

              const extraGameObject = extraGames.value.find((game) => game.id === queryExtraGameCompletedId.value)
              if (extraGameObject !== undefined) {
                if (currentLoginAPICall !== null) {
                  currentLoginAPICall.then(() => {
                    gameCompletedModalData.value = {
                      bitsAwarded: bits,
                      extraGameObject
                    }
                  })
                }
              }
            }

            if (queryGameId.value < 0 && (queryClassId.value || loggedInData.value)) {
              showMediaQuizPopup()
            } else {
              const selectedGame = extraGames.value.find((game) => {
                return game.id === queryGameId.value
              })

              if (selectedGame) {
                showDetailsModal(selectedGame)
              }
            }
          }
        })
    }

    getThemeMissions()

    function getThemeMissions() {
      ApiHandler.api.get('game/mission/get-themed-missions').then(response => {
        // console.log('getExtraGames response', response)
        if (response.status === 200) {
          themeMissions.value = response.data
        }
      }).catch(error => {
        console.log(error)
      })
    }

    function logout() {
      localStorage.removeItem('schoolClass')
      loggedInData.value = null
    }

    function showDetailsModal(gameData) {
      const bitsEarned = loggedInData.value ? loggedInData.value.extraGames.content.filter(game => game.id === gameData.id)[0].bitsEarned : null

      currentDetailModalData.value = {
        active: true,
        title: gameData.name,
        description: gameData.description,
        timesPlayed: loggedInData.value !== null ? gameData.extraGamePlayedCount : null,
        rewardAmount: gameData.bitsAwarded,
        imageSrc: gameData.mediaFileUrl,
        bitsEarned: bitsEarned,
        maximumBitsReached: loggedInData.value ? (bitsEarnedToday.value >= loggedInData.value.extraGames.maxBitsEarnable) : false,
        useInlineSvg: false,
        startButtonPressed: () => startExtraGameSession(gameData),
        closeButtonPressed: () => closeDetailsModal(),
      }
    }

    let tempGameDataHolder = null

    function closeDetailsModal() {
      currentDetailModalData.value = BASE_EXTRA_GAME_MODAL_DATA
    }

    function showNoExtraGamesModal(data) {
      isNoExtraGamesModalShown.value = true
      tempGameDataHolder = data
    }

    function closeNoExtraGamesShownModal() {
      isNoExtraGamesModalShown.value = false
      if (tempGameDataHolder != null) {
        showDetailsModal(tempGameDataHolder)
        tempGameDataHolder = null
      }
    }

    function startExtraGameSession(game) {
      const externalGameUrl = game.link
      let schoolClassLocalStorage = localStorage.getItem('schoolClass')
      if (schoolClassLocalStorage) schoolClassLocalStorage = JSON.parse(schoolClassLocalStorage)
      if (schoolClassLocalStorage) {
        // create session
        const formData = new FormData()
        formData.append('extraGameId', game.id)
        formData.append('schoolClassId', schoolClassLocalStorage.schoolClass.id)
        ApiHandler.api.post('extra-game/create-session', formData)
          .then(response => {
            const sessionId = response.data.sessionId
            // console.log('create extra game session response', response)
            const externalLink = externalGameUrl + '?sessionId=' + sessionId
            window.open(externalLink, '_blank')
          }).catch(error => {
            console.log(error)
          })
      } else {
        window.open(externalGameUrl, '_blank')
      }
    }

    function showMediaQuizPopup() {
      currentDetailModalData.value = {
        active: true,
        title: 'MediaQuiz',
        description: 'Hoe mediawijs ben jij? Ontdek het in de MediaQuiz! Verdien extra bits voor je klas of doe kennis op die je goed van pas komt. De vragen in de MediaQuiz zijn gemaakt door verschillende organisaties die bijdragen aan MediaMasters. Veel plezier!',
        timesPlayed: loggedInData.value ? loggedInData.value.mediaquiz.timesPlayed : null,
        rewardAmount: null,
        imageSrc: require('../assets/images/img_gamebadge_no_shadow.svg'),
        maximumBitsReached: loggedInData.value ? (loggedInData.value.mediaquiz.bitsEarned >= loggedInData.value.mediaquiz.maxBitsEarnable) : false,
        useInlineSvg: true,
        closeButtonPressed: () => closeDetailsModal(),
        // startButtonPressed: () => {
        //   console.log('start button')
        //   router.push('/media-quiz')
        // },
        route: '/game-introduction'
      }

      // console.log('showMediaQuizPopup', currentDetailModalData)
    }

    function startThemeMission(missionId) {
      router.push({ name: ROUTE_NAMES.GAME_SLIDE, params: { id: missionId, slideIndex: 1 } })
    }

    function closeCompletedGameModal() {
      gameCompletedModalData.value = null
    }

    // Scroll to lower section
    const upperSectionHeight = ref(null)

    function goToLowerSection() {
      const height = upperSectionHeight.value.clientHeight
      document.getElementById('main-content').style.scrollBehavior = 'smooth'
      setTimeout(() => {
        document.getElementById('main-content').scroll(0, height)
      }, 600)
    }

    return {
      showMediaQuizPopup,
      currentDetailModalData,
      extraGames,
      showSubsection,
      loggedInData,
      weekStatus,
      logout,
      timeUntil,
      postWeek,
      preWeek,
      onWeek,
      startExtraGameSession,
      showDetailsModal,
      closeDetailsModal,
      themeMissions,
      startThemeMission,
      mediaQuizBoxData,
      gameCompletedModalData,
      closeCompletedGameModal,
      showNoExtraGamesModal,
      isMobile,
      isNoExtraGamesModalShown,
      closeNoExtraGamesShownModal,
      mediaLeft,
      extraLeft,
      beforeEnterMedia,
      beforeEnterExtra,
      afterLeaveExtra,
      afterLeaveMedia,
      goToLowerSection,
      upperSectionHeight,
      bitsEarnedToday,
      bitsEarnedTodayMediaQuiz,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

// temp section pre week 'under construction
.pre-week-section {
  display: flex;
  justify-content: center;
  width: 100%;

  .pre-week-section-inner {
    h1 {
      margin-bottom: 16px;
    }

    flex-direction: column;
    max-width: 1000px;
    color: white;

    img {
      margin-top: 32px;
      width: 600px;
    }
  }
}

// Animations
.slide-left-enter-from,
.slide-left-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.4s ease;
}

.slide-right-enter-from,
.slide-right-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.4s ease;
}

.top-content {
  display: flex;
  flex-direction: column;
  gap: rem(10);
  position: relative;
}

.blue-background {
  position: absolute;
  width: 100vw;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: -1;
  box-shadow: inset 0 0 0 2000px rgba(33, 100, 200, 0.4);
  clip-path: polygon(50% 0, 100% 0, 100% 50%, 100% 100%, 50% 100%, 50% 100%);
  transition: all 0.4s ease;
}

.blue-background-media {
  clip-path: polygon(50% 0, 100% 0, 100% 50%, 100% 100%, 100% 100%, 100% 100%);
  transition: all 0.4s ease;
  height: 100%;
}

.blue-background-extra {
  clip-path: polygon(50% 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 0 100%);
  transition: all 0.4s ease;
  height: 100%;
}

.top-content-options {
  color: white;
  position: relative;
  display: flex;
  padding-bottom: rem(50);
}

.title {
  font-size: rem(30);
  font-weight: 700;
  margin-bottom: rem(32);
}

.description {
  min-height: rem(140);
  margin-bottom: rem(16);
  font-size: rem(16);
  color: var(--gray_light);
}

@media screen and (min-width: $screen-sm) {
  .description {
    min-height: rem(100);
    margin-bottom: rem(16);
    font-size: rem(16);
  }
}

@media screen and (min-width: $screen-md) {
  .title {
    font-size: rem(48);
  }

  .description {
    font-size: rem(18);
  }
  .description {
    min-height: rem(80);
  }
}

@media screen and (min-width: $screen-xl) {
  .description {
    min-height: rem(60);
  }
}

.bits-bar {
  margin-bottom: rem(16);
  max-width: rem(400);
}

.bits-bar-left {
  margin-right: auto;
}

.bits-bar-right {
  margin-left: auto;
}

.top-content-options > div {
  width: 50%;
}

.top-content-media-quiz {
  padding: rem(20);
}

.top-content-extra-games {
  padding: rem(20);
  text-align: end;
}

.shield-center {
  display: none;
}

@media screen and (min-width: $screen-md) {
  .top-content-media-quiz {
    padding: rem(120) rem(50) rem(100) rem(50);
  }

  .top-content-extra-games {
    padding: rem(120) rem(50) rem(100) rem(50);
    text-align: end;
  }
}

@media screen and (min-width: $screen-lg) {
  .top-content-media-quiz {
    padding: rem(120) rem(120) rem(100) rem(50);
  }

  .top-content-extra-games {
    padding: rem(120) rem(50) rem(100) rem(120);
    text-align: end;
  }

  .shield-center {
    display: block;
    position: absolute;
    height: 40vw;
    max-height: rem(600);
    min-height: rem(480);
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: $screen-xxl) {
  .top-content-media-quiz {
    padding: rem(120) rem(150) rem(100) rem(150);
  }
  .top-content-extra-games {
    padding: rem(120) rem(150) rem(100) rem(150);
  }
}

.progress-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  // @include position(absolute, $bottom: rem(-44), $left: rem(50), $right: rem(50));
}

.progress-bar {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: rem(-35);
  bottom: 50%;
  transform: translateY(50%);
}

.daily-bits-text {
  margin: rem(32) auto rem(64) auto;
  color: var(--gray_light);
  text-align: center;
}

.maximum-bits-reached-message {
  background-color: #FE006E;
  padding: rem(10) rem(30);
  border-radius: rem(25);
  text-align: center;
  margin: 0 auto;
  width: auto;
  color: white;
  max-width: rem(820);
}

.maximum-bits-reached-title {
  font-weight: 500;
  line-height: rem(22);
  margin-bottom: rem(7);
  font-size: rem(17);
}

.maximum-bits-reached-description {
  font-weight: 400;
  line-height: rem(22);
  font-size: rem(17);
}

.lower-section-media-quiz {
  background-color: #221B3B;
  background: linear-gradient(180deg, rgba(29, 25, 52, 1) 0%, rgba(29, 25, 52, 1) 0%, rgba(123, 21, 84, 1) 100%);
  box-shadow: inset 0 20px 20px -20px black;
  position: relative;
  min-height: rem(550);
  width: 100%;
}

.media-quiz-banner-img {
  position: absolute;
  right: 50%;
  height: 100%;
  transform: translateX(50%);
  object-fit: contain;
  max-height: rem(1200);
}

.media-quiz-banner {
  position: absolute;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

.media-quiz-title-wrapper {
  text-align: center;
  color: white;
  position: absolute;
  top: 50px;
  right: 50%;
  transform: translateX(50%);
}

/* 4K adjustment */
@media screen and (min-height: 1100px) {
  .lower-section-media-quiz {
    min-height: none;
    height: 70vh;
  }

  .media-quiz-banner {
    bottom: auto;
    top: 55%;
  }
}

.media-quiz-title {
  font-weight: 400;
  color: white;
}

.media-quiz-title-big {
  margin-top: rem(15);
  font-size: rem(80);
  font-weight: 400;
}

.lower-section-extra {
  background-color: #24387E;
  background: linear-gradient(180deg, rgba(29, 56, 126, 1) 0%, rgba(29, 56, 126, 1) 0%, rgba(44, 34, 72, 1) 100%);
  box-shadow: inset 0 20px 20px -20px black;
  padding: rem(100) rem(20) rem(50) rem(20);
  flex-direction: column;
  gap: rem(30);
}

@media screen and (min-width: $screen-md) {
  .lower-section-extra {
    padding: rem(100) rem(130) rem(50) rem(130);
  }
}

.game-tile-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(330px, 372px));
  gap: rem(50);
  margin-bottom: rem(80);
  justify-content: center;
}

.logo-wrapper {
  .logo {
    width: 206px;
  }
}

.lower-section-title {
  margin-top: rem(60);
  margin-bottom: rem(30);
  color: white;
}

@media screen and (max-width: 800px) {
  .lower-section {
    padding: rem(16);
  }

  .game-tile-wrapper {
    margin-bottom: 0;
  }
}
</style>
