<template>
  <Modal>
    <template #content class="details">

      <div class="game-box-wrapper">
        <h1 class="title"> Speel zonder bits </h1>
        <p class="description"> Als je ervoor kiest om verder te spelen zonder je klassennaam in te vullen, verdien je geen bits.
        </p>

        <div class="button-wrapper">

          <div class="subtitle-text">Voer je klassennaam in</div>
          <input id="login-class-name-input"
                 v-model="className"
                 :class="apiError ? 'input-error' : ''"
                 :placeholder="$t('LOGIN.PLACEHOLDER')"
                 class="login-input"
                 type="text"
          >
          <div v-if="apiError" class="error-flash-block">
            Er is geen klas met deze naam gevonden.
          </div>
          <button class="base-button start-game" @click="onButtonPress">
            <span>Speel voor bits</span>
          </button>
          <button class="base-button" @click="continueCallback">
            <span>Spelen zonder klas</span>

          </button>
          <div class="subtitle-text"> Je verdient geen extra bits</div>

        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import ApiHandler from '../../../utils/handlers/ApiHandler'
import Modal from './Modal'

export default {
  name: 'PlayWithoutBitsModal',
  components: { Modal },
  props: {
    continueCallback: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    className: '',
    apiError: false,
  }),
  methods: {
    onButtonPress() {
      const formData = new FormData()
      formData.append('className', this.className)
      ApiHandler.api.post('/auth/login-extra-game-class', formData)
        .then(() => {
          this.continueCallback()
        })
        .catch(() => {
          this.apiError = true
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.details {
  width: rem(500);
  position: relative;
}

.game-box-wrapper {
  display: flex;
  flex-direction: column;
  padding: rem(20) rem(50);
  align-items: center;
  color: white;

  .title {
    margin-bottom: rem(20);
  }

  .description {
    margin-bottom: rem(45);
  }
}

.login-input {
  height: 50px;
  width: 100%;
  border-radius: 8px;
  background-color: #FEFEFE;
  box-shadow: 2px 2px 0 0 rgba(8, 13, 43, 0.2);
  padding: 16px;
  outline: none;
  font-family: 'Rubik', sans-serif;
  color: #373B52;
  text-align: center;
  font-size: 16px;
  border: none;
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  gap: rem(20);
  width: rem(400);
}

.start-game {
  background-color: var(--primary_color);
  height: rem(59);
  width: 100%;
  display: flex;
  align-items: center;
  padding-right: rem(6.5);
  color: white;
  margin-top: rem(5);
  margin-bottom: rem(60);

  span {
    text-align: center;
    flex-grow: 1;
  }
}

.subtitle-text {
  opacity: 0.8;
  font-size: rem(15);
}

.error-flash-block {
  height: 44px;
  width: 100%;
  border-radius: 8px;
  background-color: rgba(217, 82, 107, 0.2);
  color: white;
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>
