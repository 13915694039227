<template>
  <div v-if="dataReady" class="quiz-slide">
    <SingleChoice
      v-if="missionSlideTypeId === 7"
      :slide-content="slideContent"
      :is-quiz="true"
      :class-id="classId"
      @nextSlide="nextSlide"
    >
    </SingleChoice>

    <MultipleChoice
      v-if="missionSlideTypeId === 8"
      :slide-content="slideContent"
      :is-quiz="true"
      :class-id="classId"
      @nextSlide="nextSlide"
    >
    </MultipleChoice>

    <DragAndDrop
      v-if="missionSlideTypeId === 6"
      :slide-content="slideContent"
      :is-quiz="true"
      :class-id="classId"
      @nextSlide="nextSlide"
    >
    </DragAndDrop>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import { SET_SLIDE_CONTENT } from '@/store/mutations'
import { ROUTE_NAMES } from '@/router'
import { GET_SLIDE_CONTENT_MEDIA_QUIZ } from '@/store/actions'
import SingleChoice from '@/components/tasks/SingleChoice'
import MultipleChoice from '@/components/tasks/MultipleChoice'
import DragAndDrop from '@/components/tasks/DragAndDrop'
import DomHandler from '@/utils/handlers/DomHandler'

export default {
  name: 'QuizSlide',
  components: {
    DragAndDrop,
    MultipleChoice,
    SingleChoice,
  },
  props: {
    classId: {
      type: String,
      required: false,
      default: null
    },
    slidesSeen: {
      type: Array,
      default: () => []
    }
  },
  emits: ['increaseAnsweredCorrectCounter', 'increasePlayCounter', 'newSlideSeen'],
  data: () => ({
    dataReady: false,
    missionSlideTypeId: 7,
    taskTypes: {
      6: DragAndDrop,
      7: SingleChoice,
      8: MultipleChoice,
    },
    slideContent: {}
  }),
  computed: {
    ...mapGetters({
      bits: 'getBits',
    }),
    isMobile() {
      return DomHandler.isMobile()
    },
  },
  created() {
    // console.log('hitgging quiz?')
    // const urlSlide = parseInt(this.index)
    // const urlMission = parseInt(this.id)
    // this.$store.commit(SET_SLIDE, isNaN(urlSlide) ? 1 : urlSlide)
    // this.$store.commit(SET_MISSION, isNaN(urlMission) ? 1 : urlMission)
    this.getSlide()
  },
  methods: {
    getSlide(random = false) {
      // const randomQuestion = random || !this.$route.params.index
      // console.log('getting slide', randomQuestion)
      const payload = {
        questionId: this.$route.params.index || null,
        previouslyAnsweredSlides: this.slidesSeen
      }

      // apiHandler.api.get(`/game/mission/${this.currentMission}/slide/${this.currentSlide}`)
      this.$store.dispatch(GET_SLIDE_CONTENT_MEDIA_QUIZ, payload)
      // this.$store.dispatch(GET_SLIDE_CONTENT_MEDIA_QUIZ, { mission: this.currentMission, slide: this.currentSlide })
        .then(response => {
          // console.log('response media quiz', response)
          this.$store.commit(SET_SLIDE_CONTENT, response)
          this.slideContent = response
          this.$emit('newSlideSeen', this.slideContent.mediaQuizSlideId)
          this.missionSlideTypeId = response.missionSlideType.id
          this.dataReady = true

          // remove links
          // note: dirty solution
          this.setDoNotOpenLinksIfMobileNewTabs()
        }).catch(error => {
          console.log(error.response)
        })
    },
    nextSlide(answeredCorrect) {
      if (answeredCorrect) {
        this.$emit('increaseAnsweredCorrectCounter')
      }
      this.$emit('increasePlayCounter')

      if (this.$route.params.index) {
        this.$router.push({ name: ROUTE_NAMES.GAME_QUIZ_SLIDE })
      }
      this.slideContent = null
      this.dataReady = false
      this.getSlide(true)
    },
    setDoNotOpenLinksIfMobileNewTabs() {
      if (this.isMobile) {
        this.$nextTick(() => {
          const quizSlideHeader = document.querySelector('.quiz-slide-header')
          if (quizSlideHeader) {
            const links = quizSlideHeader.querySelectorAll('a')
            links.forEach((link) => {
              link.removeAttribute('target')
            })
          }
        })
      }
    }
  }
}
</script>

<style scoped>

.quiz-slide {
  display: inherit;
  flex-direction: inherit;
  flex-grow: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: inherit;
}

</style>
