<template>
  <GameSlide :data-ready="dataReady"
             :mission-slide-type-id="missionSlideTypeId"
             :show-bits="showBits"
             :slide-content="slide"
             @slide-complete="nextSlide()"/>
</template>

<script>
import { mapGetters } from 'vuex'
import { ROUTE_NAMES } from '@/router'
import { GET_SLIDE_CONTENT } from '@/store/actions'
import { SET_SESSION, SET_SLIDE_CONTENT } from '@/store/mutations'
import LocalDataHandler from '../../utils/handlers/LocalDataHandler'
import GameSlide from './GameSlide.vue'

export default {
  name: 'GameSlideByIndex',
  components: {
    GameSlide
  },
  props: {
    slideIndex: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data: () => ({
    dataReady: false,
  }),
  computed: {
    ...mapGetters({
      mission: 'getMission',
      slide: 'getSlideContent',
      bit: 'getBits',
      session: 'getSession'
    }),
    missionSlideTypeId() {
      return this.slide && this.slide.missionSlideType ? this.slide.missionSlideType.id : 0
    },
  },
  created() {
    const urlSlide = parseInt(this.slideIndex)
    const urlMission = parseInt(this.id)
    const sessionData = this.createFakeSessionData(urlMission, urlSlide, this.session ? this.session.bitsEarned : 0)
    this.$store.commit(SET_SESSION, sessionData)
    this.getSlide(urlSlide)
  },
  methods: {
    getSlide(slideIndex) {
      this.$store.dispatch(GET_SLIDE_CONTENT, { mission: this.id, slide: slideIndex })
        .then(response => {
          console.log(response)
          if (response.missionCategory.id === 3 && !LocalDataHandler.isAdmin()) {
            // console.log('this is a week of mission, leaving')
            this.$router.push({ name: ROUTE_NAMES.EXTRAS })
          }
          this.$store.commit(SET_SLIDE_CONTENT, response)
          this.dataReady = true
        })
        .catch(error => {
          console.error(error)
        })
    },
    nextSlide() {
      console.log('we are here')

      let currentSubSlideIndex = this.session.currentSubSlideIndex ?? 1
      let currentSlideIndex = this.session.currentMissionSlideIndex
      if (this.slide.questionContent.subSlides?.length ?? 0) {
        if (currentSubSlideIndex < this.slide.questionContent.subSlides.length) {
          currentSubSlideIndex++
        } else {
          currentSubSlideIndex = 1
          currentSlideIndex++
        }
      } else {
        currentSlideIndex++
      }

      const sessionData = this.createFakeSessionData(parseInt(this.id), currentSlideIndex, this.session.bitsEarned, currentSubSlideIndex)
      this.$store.commit(SET_SESSION, sessionData)
      if (this.slide.slideIndex === this.slide.missionTotalSlideCount) {
        this.$router.push({ name: ROUTE_NAMES.GAME_COMPLETE })
      } else {
        this.$router.push({ name: ROUTE_NAMES.GAME_SLIDE, params: { slideIndex: sessionData.currentMissionSlideIndex } })
      }
    },
    //used to create psuedo session data
    createFakeSessionData(missionId, slideIndex, bits, subSlideIndex) {
      return {
        id: -1,
        createdAt: '2021-10-13 16:40:45',
        updatedAt: '2021-10-13 16:50:45',
        finishedAt: null,
        bitsEarned: bits,
        schoolClassId: -1,
        missionId: missionId,
        currentStreak: 0,
        currentMissionSlideId: 379,
        currentMissionSlideIndex: slideIndex,
        currentSubSlideIndex: subSlideIndex,
      }
    }
  }
}
</script>

<style>

</style>
