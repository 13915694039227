<template>
  <div class="card"
       :style="`z-index: ${cardZIndex}; top: ${cardTop}%; width: calc(100% - ${cardWidthReduction}%)`"
       :class="isTopCard ? 'top-card' : null"
  >

    <div v-if="correctAnswer !== null"
         class="card-feedback"
         :class="hasAnsweredTrue() ? 'positive-feedback-container' : 'negative-feedback-container'"
    >
      {{ $t(hasAnsweredTrue() ? 'FOOTER.PHISHING.TRUE' : 'FOOTER.PHISHING.PHISHING') }}
    </div>

    <div class="card-sender">
      <div class="card-image">
        <img src="@/assets/icons/icn_user.svg">
      </div>
      <div class="card-section-title">Afzender:</div>
      <div class="card-section-text" v-html="showSentFromAddress ? senderEmail : senderName"></div>
      <img v-if="showSentFromAddress"
           class="show-sender-button"
           src="@/assets/icons/icn_reveal.svg"
           @click="toggleSendFromAddress"
      >
      <img v-else class="show-sender-button" src="@/assets/icons/icn_hide.svg" @click="toggleSendFromAddress">
    </div>

    <div class="card-subject">
      <div class="card-image">
        <img src="@/assets/icons/icn_chat.svg">
      </div>
      <div class="card-section-title">Onderwerp:</div>
      <div class="card-section-text">{{ subject }}</div>
    </div>
    <transition name="fade">
      <div v-show="hoveredUrl" ref="linkDisplay" class="link-display"> {{ hoveredUrl }}</div>
    </transition>
    <div ref="textElement" class="card-text scroll-bar-styling" v-html="text"></div>

  </div>
</template>

<script>
export default {
  name: 'PhishingCard',
  props: {
    cardWidthReduction: {
      type: Number,
      required: true
    },
    cardZIndex: {
      type: Number,
      required: true
    },
    cardTop: {
      type: Number,
      required: true
    },
    isTopCard: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      required: true
    },
    subject: {
      type: String,
      required: true
    },
    senderEmail: {
      type: String,
      required: true
    },
    senderName: {
      type: String,
      required: true
    },
    correctAnswer: {
      default: null,
    },
    messageTypes: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showSentFromAddress: false,
    hoveredUrl: null,
    urls: []
  }),
  mounted() {
    const text = this.$refs.textElement
    // const links = text.getElementsByTagName('a')
    const links = text.querySelectorAll('a')
    links.forEach((link, index) => {
      this.urls.push(link.getAttribute('href'))
      // console.log('Phishing card link', link)
      link.setAttribute('onClick', 'javascript: return false;')
      link.style.position = 'relative'
      link.click(function(event) {
        event.preventDefault()
      })
      link.addEventListener('mouseenter', (event) => {
        this.hoveredUrl = this.urls[index]
        event.target.appendChild(this.$refs.linkDisplay) })
      link.addEventListener('mouseleave', () => { this.hoveredUrl = null })
    })
  },
  methods: {
    toggleSendFromAddress() {
      this.showSentFromAddress = !this.showSentFromAddress
    },
    hasAnsweredTrue() {
      return this.correctAnswer === this.messageTypes.real
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/_base.variables.scss";
@import "~@/assets/css/_base.mixins.scss";
@import "~@/assets/css/global.scss";

.card {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #F7F8FB;
  border-radius: rem(20);
  height: rem(500);
  left: 50%;
  transform: translate(-50%, 0);
  color: var(--text_color);
  padding: 0 rem(30);
  text-align: left;
  font-size: rem(22);
  box-shadow: rem(0) rem(5) rem(10) rem(0) #000000;

  &.top-card {
    position: relative;
  }

  :deep(a) {
    color: var(--highlight_dark_color);
  }

  .card-feedback {
    position: fixed;
    color: #FFFFFF;
    border: none;
    border-radius: rem(8);
    box-shadow: rem(4) rem(4) 0 0 rgba(0,0,0,0.1);
    font-family: 'Rubik', sans-serif;
    font-weight: 500;
    letter-spacing: 0;
    line-height: rem(22);
    padding: rem(15) rem(30);
    text-align: center;
    transition: filter 0.15s;

    min-width: rem(162);

    transform: translate(rem(-65), rem(5)) rotate(-15deg);
  }

  .card-sender, .card-subject {
    display: flex;
    align-items: center;
  }
  .card-sender {
    padding: rem(14) 0;
    border-bottom: 2px solid #BABABA;
  }
  .card-subject {
    padding: rem(20) 0;
  }
  .card-image {
    height: 20px;
    width: 20px;
    margin-right: rem(20);
  }
  .card-section-title {
    margin-right: rem(12);
    font-weight: 600;
  }
  .card-section-text {
    margin-right: auto;
  }
  .show-sender-button {
    cursor: pointer;
  }

  .card-text {
    padding: rem(25);
    margin:rem(25);
    line-height: rem(35);
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: rem(10);
      border-radius: rem(10);
      margin-bottom: rem(20);
      position: absolute;
      left:10px
    }

    &::-webkit-scrollbar-track {
    }

    &::-webkit-scrollbar-thumb {
      background-color: #b6b9c7;
      @include border-radius(rem(4));
    }
  }

  .card-text {
    flex-grow: 1;
    background-color: #FFFFFF;
    margin: 0 rem(-30);
    border-radius: 0 0 rem(20) rem(10);
  }
}

.link-element{
  position: relative
}

.link-display{
    position: absolute;
    left: 0;
    bottom: 20px;
    z-index: 2;
    background-color: #E8E9F0;
    border: 3px solid white;
    border-radius: 0.625rem;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

@media screen and (min-width: $screen-xl) {
  .card {
    font-size: rem(15);
    height: rem(400);

    .card-sender {
      padding: rem(7) 0;
    }

    .card-subject {
      padding: rem(10) 0;
    }

    .card-text {
      padding: rem(12);
    }
  }
}

@media screen and (max-width: $screen-720p) {
  .card {
    max-height: 350px;
    overflow-y: auto;
  }

  :deep(.card-text p) {
    font-size: 16px;
    line-height: 1.5;
  }
}
</style>
