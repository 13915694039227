<template>
  <div :class="{'hidden' : hideDueToHistory}" class="game-footer-wrapper">
    <div v-if="defaultFooterActive" class="footer-button-wrapper joker-button-wrapper">
      <JokerSelector v-if="isJokerSelectorShown" :has-finished-answer="!continueButtonDisabled"/>
      <button :disabled="continueButtonDisabled" class="continue-button pink-button" @click="continueButtonClick">
        {{ continueButtonText }}
      </button>
    </div>
    <div v-else-if="choiceButtonsFooterActive" class="footer-button-choice-wrapper joker-button-wrapper">
      <JokerSelector v-if="isJokerSelectorShown" :has-finished-answer="!continueButtonDisabled"/>
      <div :class="{ 'jokers-hidden': !isJokerSelectorShown }" class="feedback-buttons">
        <button :disabled="false"
                class="positive-feedback-container base-button"
                @click="choiceData.positiveChoiceClick">{{ choiceData.positiveChoiceText }}
        </button>
        <button :disabled="false"
                class="negative-feedback-container base-button"
                @click="choiceData.negativeChoiceClick">{{ choiceData.negativeChoiceText }}
        </button>
      </div>
    </div>
    <div v-else
         :class="{
           'correct-result': feedbackResult.successPercentage >= 1,
           'wrong-result': feedbackResult.successPercentage <= 0,
           'average-result': feedbackResult.successPercentage > 0 && feedbackResult.successPercentage < 1
         }"
         class="result-box"
    >
      <div class="feedback-wrapper">
        <div class="result-icon"></div>
        <div class="result-text-container">
          <h1 class="title">{{ footerTitle }}</h1>
          <p class="result-text-header">{{ feedbackResult.title }}</p>
          <p class="result-text" v-html="feedbackResult.text"></p>
        </div>
      </div>
      <button class="action-button pink-button" @click="actionButtonCallback">{{ actionButtonText }}</button>
    </div>
  </div>
</template>

<script>
import { computed } from '@vue/runtime-core'
import i18n from '@/setup/i18n/i18n'
import JokerSelector from '@/components/elements/JokerSelector'
import { watch } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'GameFooter',
  components: {
    JokerSelector,
  },
  props: {
    continueButtonText: {
      type: String,
      required: false,
      default: 'no continue button text set'
    },
    /**
     * @param positiveChoiceText The text to display in the positive button option.
     * @param negativeChoiceText The text to display in the negative button option.
     * @param positiveChoiceClick The callback for when the positive button was pressed.
     * @param negativeChoiceClick The callback for when the positive button was pressed.
     */
    choiceData: {
      type: Object,
      required: false,
      default: null
    },
    continueButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    continueButtonClick: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    class: {
      type: String,
      default: null,
    },
    forceNext: {
      type: Boolean,
      default: false,
    },
    /**
     * @param successPercentage number (between 0 - 1), indicating how much of the answers where correctly answered.
     * @param title bold text indicating introducing short or large text answer.
     * @param text large text explaining what correct answers where on 'improve' & 'incorrect' feedback screens.
     */
    feedbackResult: {
      type: Object,
      required: false,
      default: null
    },
    feedbackButtonText: {
      type: String,
      required: false,
      default: ''
    },
    feedbackButtonClick: {
      type: Function,
      required: false,
      default: () => {
      }
    },
    hasFeedbackPopup: {
      type: Boolean,
      default: false
    },
  },
  setup(props) {
    const store = useStore()
    const checkingHistory = computed(() => store.getters.getHistoryLocation)
    const hideDueToHistory = computed(() => checkingHistory.value !== null)

    const defaultFooterActive = computed((() => !props.feedbackResult && !props.choiceData) || props.forceNext)
    const choiceButtonsFooterActive = computed(() => !props.feedbackResult && props.choiceData)
    const actionButtonText = computed(() => {
      if (defaultFooterActive.value) {
        return props.continueButtonText
      } else if (props.hasFeedbackPopup) {
        return props.feedbackButtonText
      } else {
        return i18n.global.tc('STATIC.NEXT')
      }
    })
    const actionButtonCallback = computed(() => defaultFooterActive.value ? props.continueButtonClick : props.feedbackButtonClick)
    const footerTitle = computed(() => {
      if (!props.feedbackResult) {
        return ''
      }
      const successPercentage = props.feedbackResult.successPercentage
      if (successPercentage <= 0) {
        return i18n.global.tc('MISSION.GLOBAL.WRONG_ANSWER_TITLE')
      } else if (successPercentage === 1) {
        return i18n.global.tc('MISSION.GLOBAL.CORRECT_ANSWER_TITLE')
      } else if (successPercentage > 0 && successPercentage < 1) {
        return i18n.global.tc('MISSION.GLOBAL.PARTIALLY_CORRECT_ANSWER_TITLE')
      }
    })

    const localStorageLoggedInData = JSON.parse(localStorage.getItem('schoolClass'))
    const isJokerSelectorShown = computed(() => store.getters.getAvailableJokers?.length)

    watch(() => props.feedbackResult, feedbackResult => {
      if ((localStorageLoggedInData === null || localStorageLoggedInData?.schoolClass.hasExtraSounds) && props.feedbackResult?.successPercentage > -0.01) {
        playSound(feedbackResult.successPercentage > 0)
      }
    })

    function playSound(answeredCorrect) {
      const audio = new Audio(answeredCorrect ? require('@/assets/audio/correct.mp3') : require('@/assets/audio/incorrect.mp3'))
      audio.loop = false
      audio.play()
    }

    return {
      footerTitle,
      actionButtonText,
      defaultFooterActive,
      actionButtonCallback,
      choiceButtonsFooterActive,
      isJokerSelectorShown,
      hideDueToHistory,
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";
@import "~@/assets/css/global.scss";

.hidden {
  opacity: 0;
  pointer-events: none;
}

.game-footer-wrapper {
  margin-top: auto;
  min-height: 10%;
  width: 100%;
  transition: opacity 0.5s ease;

  .joker-button-wrapper {
    //display: grid;
    //grid-template-columns: 1fr min-content;
    //align-items: center;
  }

  @media screen and (min-width: $screen-720p) {
    position: sticky;
    bottom: 0;
    min-height: rem(80);
    z-index: 9;
  }

  @media screen and (max-width: $screen-720p) {
    min-height: rem(70);
  }
}

.result-box, .joker-button-wrapper {
  position: relative;
  padding: rem(24) rem(32);

  @media screen and (max-width: $screen-720p) {
    padding: rem(8) rem(16);
  }
}

.footer-button-choice-wrapper {
  position: relative;
  border-top: var(--light-grey-border);
  min-height: inherit;

  .feedback-buttons {
    display: flex;
    gap: 2rem;
    justify-content: center;

    &.jokers-hidden {
      gap: 10rem;
    }
  }

  .base-button {
    margin-bottom: 0;
    color: white;
    width: rem(220);
  }
}

.footer-button-wrapper {
  border-top: var(--light-grey-border);
  min-height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .select-joker-prompt {
    padding: 1.3rem;
    background-color: var(--back_color);
    border-radius: rem(15);
  }

  .continue-button {
    transition: background-color 0.5s ease;
    margin-left: auto;
    //margin-bottom: 10px;
  }

  .continue-button:disabled {
    background: #ACABB8;
    cursor: not-allowed;

    &:hover {
      filter: brightness(100%)
    }

    &:active {
      filter: brightness(100%)
    }
  }
}

.result-box {
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .feedback-wrapper {
    display: flex;
    align-items: flex-start;
    gap: rem(44);

    .result-icon {
      width: rem(78);
      height: rem(78);
      flex-shrink: 0;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
  }

  .result-text-container {
    .result-text {
      text-align: left;
      max-width: rem(1145);
      font-size: rem(24);
      line-height: rem(38);
      color: white;
      padding-right: 10px;

      @media screen and (min-width: $screen-xl) {
        font-size: rem(20);
        line-height: rem(34);
      }
    }

    .result-text :deep(.correct-answer) {
      color: #00B7AC;
      font-weight: 500;
    }

    .result-text-header {
      text-align: left;
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 10px;
      color: white;
      padding-right: 10px;
    }

    .title {
      text-align: left;
      margin-bottom: rem(8);
    }
  }

  &.correct-result {
    background: rgba(0, 183, 172, 0.2);

    .title {
      color: var(--highlight_color);
    }

    .feedback-wrapper {
      .result-icon {
        background-image: url(../assets/images/img_correct.svg);
      }
    }
  }

  &.wrong-result {
    background: rgba(217, 82, 107, 0.2);

    .title {
      color: #FC5737;
    }

    .feedback-wrapper {
      .result-icon {
        background-image: url(../assets/images/img_wrong.svg);
      }
    }
  }

  &.average-result {
    background: rgba(1, 177, 243, 0.1);

    .title {
      color: #FF9720;
    }

    .feedback-wrapper {
      .result-icon {
        background-image: url(../assets/images/img_soso.svg);
      }
    }
  }
}

.action-button, .continue-button {
  cursor: pointer;
  flex-shrink: 0;
  width: fit-content;
  min-height: 50px;
  padding: 0 rem(60);
  font-weight: 600;
  justify-self: flex-end;
  min-width: 17rem;
}

@media screen and (max-width: $screen-sm) {
  .action-button, .continue-button {
    width: 100%;
    margin-bottom: 0;
  }

  .game-footer-wrapper {
    min-height: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .footer-button-wrapper {
    min-height: unset;
    padding: 0;
    display: flex;
    align-items: center;
    border-top: unset;

    .continue-button {
      margin-bottom: 0;
    }
  }
  .result-box {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;

    .feedback-wrapper {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 12px;
      margin-bottom: 1rem;

      .result-icon {
        width: 2.5rem;
        height: 2.5rem;
      }
    }

    .result-text-container {
      .title {
        font-size: rem(22);
        margin-bottom: 0;
      }

      .result-text-header {
        display: none;
        font-size: rem(18);
      }

      .result-text {
        display: none;
        font-size: rem(18);
        line-height: 1.8rem;
      }
    }
  }
}

// landscape mobile
@media (orientation: landscape) and (max-height: 500px) {
  .game-footer-wrapper {
    min-height: unset;
    //position: fixed;
    //top: 0;
    //bottom: 0;
    //left: 0;
    //right: 0;
  }
  .result-box {
    flex-direction: column;
    align-items: flex-start;

    .feedback-wrapper {
      gap: 16px;

      .title {
        font-size: 24px;
      }

      .result-text-header {
        font-size: 20px;
      }

      .result-text {
        font-size: 16px;
        line-height: 26px;
      }

      .result-icon {
        flex-shrink: 1;
      }
    }

    .action-button {
      align-self: end;
      margin-top: 10px;
    }
  }
}
</style>
