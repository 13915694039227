<template>
  <div class="comments-wrapper">
    <div class="image-container">
      <img class="comment-image" :src="data.mediaFileUrl">
    </div>

    <div class="social-icons">
      <img class="icon" :src="require('@/assets/icons/icn_like.svg')" alt="Like">
      <img class="icon" :src="require('@/assets/icons/icn_comment.svg')" alt="Comment">
      <img class="icon" :src="require('@/assets/icons/icn_share.svg')" alt="Share">
    </div>

    <div class="comments">
      <div
        v-for="(comment, index) in data.messages"
        :key="index"
        class="comment">
        <img class="profile-pic" :src="index % 2 === 0 ? require('@/assets/images/img_avatar_boy.svg') : require('@/assets/images/img_avatar_girl.svg')" alt="Profile Pic">
        <div class="text-wrapper">
          <div class="text">
            {{ comment }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExamineComment',
  props: {
    data: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/css/_base.variables.scss";
  @import "~@/assets/css/_base.mixins.scss";

  .comments-wrapper {
    background-color: #E7EBF1;
    border-radius: rem(10);
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: rem(10);
    }

    &::-webkit-scrollbar-track {
      background-color: #b6b9c7;
    }

    &::-webkit-scrollbar-thumb {
      color: var(--text_color);
      @include border-radius(rem(4));
    }

    .image-container {
      background-color: white;
      .comment-image {
        border-top-left-radius: rem(10);
        border-top-right-radius: rem(10);
        padding: 4px;
        width: 100%;
        object-fit: contain;
      }

    }

    .social-icons {
      display: flex;
      margin-bottom: rem(30);
      margin-top: 16px;

      .icon {
        margin: 0 rem(15);
      }
    }

    .comment {
      display: flex;
      padding: rem(0) rem(30) rem(25) rem(30);

      .profile-pic {
        width: rem(60);
        margin-right: rem(15);
        margin-top: auto;
      }

      .text-wrapper {
        display: flex;
        flex-grow: 1;
      }

      .text {
        margin-top: auto;
        padding: rem(15) rem(20);
        border-radius: rem(10);
        font-size: rem(21);
        line-height: rem(31);
        float: left;
        background-color: #C1D1DF;
        position: relative;

        &:before {
          @include position(absolute, $bottom:rem(0) ,$left: rem(-8));
          content: "";
          width: 0;
          height: 0;
          border-top: rem(8) solid
          transparent;
          border-right: rem(16) solid
          #C1D2DF;
          border-bottom: rem(8) solid
          transparent;
          transform: rotate(90deg);
        }
      }
    }
  }
</style>
